import { mapGetters } from 'vuex';

export default {
  data: function() {
    return {
      navs: [
        {
          id: '01',
          name: 'クーポン一覧',
          path: '/shop/coupon',
          routeName: 'ShopCoupon',
          includes: false,
        },
        {
          id: '02',
          name: '利用実績',
          path: '/shop/coupon/use',
          routeName: 'ShopCouponUse',
          includes: false,
        },
        {
          id: '03',
          name: 'クーポン作成',
          path: '/shop/coupon/register',
          routeName: 'ShopCouponRegister',
          includes: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      validFlag: 'shop/validFlag',
      isGmoOrOfficeLoginStore: 'common/isGmoOrOfficeLoginStore',
    }),
    pageNavs() {
      if (this.$permission.isGroup() || (this.isGmoOrOfficeLoginStore && !this.validFlag)) return this.navs.slice(0, 2);
      return this.navs;
    }
  },
};
