<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader :pageName="pageName" :pageNavs="pageNavs" />
      <CouponForm ref="form" :isEdit="true"></CouponForm>
    </div>
    <!--[/main]-->
    <!--[footer]-->
    <PageFooter>
      <template v-slot:footer>
        <ul class="listGrid justify-content-end">
          <li class="listGrid-item">
            <router-link class="btn btn-white" :to="{ name: 'ShopCoupon' }">キャンセル</router-link>
          </li>
          <li class="listGrid-item">
            <ActionButton class="btn btn-main" :handle-submit="register" button-text="編集する" />
          </li>
        </ul>
      </template>
    </PageFooter>
    <!--[/footer]-->
  </div>
</template>

<script>
import CouponForm from './Form.vue';
import nav from '@/mixins/nav/coupon';
import edit from '@/mixins/plugin/edit';

export default {
  name: 'ShopCouponEdit',
  data: function() {
    return {
      pageName: 'クーポン',
    };
  },
  components: {
    CouponForm,
  },
  mixins: [nav, edit],
  methods: {
    register: function() {
      this.$refs.form.handleSubmit();
    },
  },
};
</script>
