export default {
  data() {
    return {
      currentRouteName: '',
    }
  },
  mounted () {
    this.currentRouteName = this.$route.name;
  },
  beforeDestroy () {
    if (
      !this.currentRouteName.includes(this.$route.name)
      && !(this.currentRouteName.includes(this.$route.name) && this.$route.name.includes('Register'))
    ) {
      sessionStorage.removeItem(`${this.storeModule}/listParams`);
    }
  },
};
